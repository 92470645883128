<template src="./index.html" />

<script>
import {
  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BLink, BTooltip, VBTooltip, BAvatar,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import FormChiTietAnPham from '@/views/digital-library/FormChiTietAnPham.vue'
import FormPhieuMuon from '@/views/digital-library/FormPhieuMuon.vue'
export default {
  name: 'Index',
  components: {
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BLink,
    BTooltip,
    VueGoodTable,
    vSelect,
    BAvatar,
    FormChiTietAnPham,
    FormPhieuMuon,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      table: {
        fields: [
          {
            label: 'Hình ảnh',
            field: 'image',
            with: '5%',
            sortable: false,
          },
          {
            label: 'Nội dung',
            field: 'name',
            sortable: false,
          },
          {
            label: 'Lượt xem',
            field: 'count_view',
            sortable: false,
          },
          {
            label: 'Đánh giá',
            field: 'avg_score_rate',
            sortable: false,
          },
          {
            label: '',
            field: 'action',
            thClass: 'text-center',
            tdClass: 'text-center',
            sortable: false,
          },
        ],
        rows: [],
      },
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      itemsDisplay: [],
      dataSendForm: {},
      dataSendFormPhieuMuon: {},
      total: 0,
      titlePhieuMuon: "",
    }
  },
  async created() {
    // init data FROM API
    await this.loadItems()
  },
  computed: {
  },
  watch: {
    // listening change data init
  },
  methods: {
    ...mapActions({
      getListOfPublications: 'digitalLibrary/getListOfPublications',
      getListOfBorrowing: 'digitalLibrary/getListOfBorrowing',
    }),
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    async onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      await this.loadItems()
    },

    async onPerPageChange(params) {
      this.updateParams({ page: 1, perPage: params.currentPerPage })
      await this.loadItems()
    },

    // load items is what brings back the rows from server
    async loadItems() {
      this.isLoading = true
      try {
        const dsAnPham = await this.getListOfPublications()
        this.table.rows = dsAnPham.bookList.rows
        this.total = dsAnPham.bookList.total_rows
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    xemChiTietAnPham(data) {
      this.dataSendForm.anPhamId = data.id
      this.$bvModal.show('modal-ttanpham')
    },

    taoPhieuMuon(data) {
      this.dataSendFormPhieuMuon = data
      this.titlePhieuMuon = "Tạo phiếu mượn " + data.name
      this.$bvModal.show('modal-taophieumuon')
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>

<style scoped>
</style>
